var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card mb-5"},[_c('div',{staticClass:"card-content"},[_c('ValidationObserver',{ref:"add-member"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addUser.apply(null, arguments)}}},[_c('div',{staticClass:"columns is-tablet"},[_c('div',{staticClass:"column is-4-tablet"},[_c('ValidationProvider',{attrs:{"mode":"passive","name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"Email","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"email","id":"email"},model:{value:(_vm.form.email),callback:function ($$v) {_vm.$set(_vm.form, "email", $$v)},expression:"form.email"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4-tablet"},[_c('ValidationProvider',{attrs:{"mode":"passive","name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":"User Role","type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"type":"is-secondary is-light","no-outline":"","expanded":"","items":_vm.roles,"placeholder":"Select Role"},model:{value:(_vm.form.role),callback:function ($$v) {_vm.$set(_vm.form, "role", $$v)},expression:"form.role"}})],1)]}}])})],1),_c('div',{staticClass:"column is-4-tablet"},[_c('ValidationProvider',{attrs:{"mode":"passive","name":_vm.$t('farm.pond'),"rules":_vm.form.role && ![1,2].includes(_vm.form.role.value) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.pond'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"type":"is-secondary is-light","no-outline":"","expanded":"","multiple":"","items":_vm.ponds ? _vm.ponds.map(function (e){ return e.name; }) : [],"disabled":!_vm.form.role || [1,2,undefined].includes(_vm.form.role.value),"placeholder":((_vm.$t('select')) + " " + (_vm.$t('farm.pond')))},model:{value:(_vm.form.ponds),callback:function ($$v) {_vm.$set(_vm.form, "ponds", $$v)},expression:"form.ponds"}})],1)]}}])})],1)]),_c('div',{staticClass:"has-text-right"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t('add')))])],1)])])],1)]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('b-table',{attrs:{"mobile-cards":false,"data":_vm.users},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"has-text-centered"},[_vm._v(_vm._s(_vm.$t('no'))+" Data")])]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"email","label":"Email"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"name":"Email","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{model:{value:(props.row.email),callback:function ($$v) {_vm.$set(props.row, "email", $$v)},expression:"props.row.email"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"field":"role","label":"Role"},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"mode":"passive","name":"Role","rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"type":"is-secondary is-light","no-outline":"","expanded":"","items":_vm.roles,"placeholder":"Select Role"},model:{value:(props.row.role),callback:function ($$v) {_vm.$set(props.row, "role", $$v)},expression:"props.row.role"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"field":"ponds","label":_vm.$t('farm.pond')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"mode":"passive","name":_vm.$t('farm.pond'),"rules":props.row.role && ![1,2].includes(props.row.role.value) ? 'required' : ''},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('sg-select',{attrs:{"type":"is-secondary is-light","no-outline":"","expanded":"","multiple":"","items":_vm.ponds.map(function (e){ return e.name; }),"disabled":!props.row.role || [1,2,undefined].includes(props.row.role.value),"placeholder":((_vm.$t('select')) + " " + (_vm.$t('farm.pond')))},model:{value:(props.row.ponds),callback:function ($$v) {_vm.$set(props.row, "ponds", $$v)},expression:"props.row.ponds"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"field":"action","label":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-button',{attrs:{"type":"is-danger is-light","icon-right":"trash-can-outline"},on:{"click":function($event){return _vm.deleteUser(props.index)}}})]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }