<template>
  <div>
    <b-steps size="is-medium" v-model="currentStep">
      <b-step-item :label="$t('farm.registration')" step="1">
        <farm-sign-up @update-data="value => updateData(0, value)" ref="farm-sign-up"/>
      </b-step-item>
      <b-step-item :label="`${$t('add')} ${$t('farm.pond')}`" step="2">
        <add-pond @update-data="value=> updateData(1, value)" ref="add-pond"/>
      </b-step-item>
      <b-step-item :label="`${$t('add')} User`" step="3">
        <add-member :ponds="stepData[1].value" @update-data="value=> updateData(2, value)" ref="add-member"/>
      </b-step-item>
      <b-step-item :label="$t('wq.wqs')" step="4">
        <water-quality-setting @update-data="value => updateData(3,value)" ref="water-quality-setting"/>
      </b-step-item>

      <template
          slot="navigation"
          slot-scope="{previous, next}">
        <div class="is-flex is-justify-content-space-between px-4">
          <b-button
              class="has-text-weight-bold"
              outlined
              type="is-dark"
              icon-left="arrow-left"
              :disabled="previous.disabled"
              @click.prevent="previous.action">
            Previous
          </b-button>
          <b-button
              class="has-text-weight-bold"
              outlined
              type="is-dark"
              icon-right="arrow-right"
              @click.prevent="nextStep(next.action)">
            {{ isLatestStep ? 'Finish' : 'Next' }}
          </b-button>
        </div>
      </template>
    </b-steps>
  </div>
</template>

<script>
import FarmSignUp from "@/components/Farm/Registration/FarmSignUp";
import AddPond from "@/components/Farm/Registration/AddPond";
import AddMember from "@/components/Farm/Registration/AddMember";
import WaterQualitySetting from "@/components/Farm/Setting/WaterQualitySetting";

export default {
  name: "FarmRegistration",
  components: {WaterQualitySetting, AddMember, AddPond, FarmSignUp},
  data: () => {
    return {
      currentStep: 0,
      stepData: [
        {
          name: 'farm-sign-up',
          canContinue: false,
        },
        {
          name: 'add-pond',
          canContinue: false,
        },
        {
          name: 'add-member',
          canContinue: true,
        },
        {
          name: 'wq-setting',
          canContinue: true,
        },
      ]
    }
  },
  computed: {
    isLatestStep() {
      return this.currentStep === this.stepData.length - 1
    },
    wqs() {
      return this.$store.state.setting.wqs
    },
  },
  methods: {
    updateData(index, value) {
      this.stepData[index].canContinue = value.canContinue
      this.stepData[index].value = value.data
    },
    async nextStep(action) {
      let next = false

      next = this.$refs[this.stepData[this.currentStep].name] ? await this.$refs[this.stepData[this.currentStep].name].canContinue() : this.stepData[this.currentStep].canContinue

      if (next) {
        if (!this.isLatestStep) action()
        else {
          let res
          this.$loading()
          res = await this.addFarm()
          if (!res.error && res.data.id) {
            let farm_id = res.data.id
            await this.addUsers(farm_id)
            if (this.wqs) await this.addWqs(farm_id)

            this.$router.push('/farm/' + farm_id)
          }
          this.$loading(false)
        }
      }
    },
    async addFarm() {
      let farm = this.stepData[0].value
      let ponds = this.stepData[1].value
      return await this.$store.dispatch('farm/addFarm', {
        city: farm.city,
        name: farm.name,
        location: farm.location.lat + ',' + farm.location.lng,
        color: "#FFFFFF",
        created_at: this.$timestamp(),
        pond: ponds,
      })
    },

    async addUsers(farm_id) {
      let users = this.stepData[2].value

      if (users && users.length) {
        let ponds = await this.$store.dispatch('farm/getPond', farm_id)

        if (!ponds.error) {
          ponds = ponds.data
          for (let i = 0; i < users.length; i++) {
            let user = users[i]

            let payload = {
              farm_id: farm_id,
              role_id: user.role.value,
              email: user.email,
              access_pond: []
            }

            if (![1, 2].includes(payload.role_id)) {
              payload.access_pond = user.ponds.map(e => {
                let find = ponds.find(f => f.name === e)
                return {
                  pond_id: find ? find.pond_id : null
                }
              }).filter(e => e)
            }

            await this.$store.dispatch('farm/addUserFarm', payload)
          }
        }
      }
    },

    async addWqs(farm_id) {
      let wqs = this.stepData[3].value

      if (wqs) {
        let timestamp = this.$timestamp()
        for (let i = 0; i < wqs.length; i++) {
          let item = wqs[i]

          let payload = {
            farm_id: farm_id,
            name: item.name,
            category: item.category,
            min_limit: item.min_limit,
            max_limit: item.max_limit,
            min_value: item.min_value,
            max_value: item.max_value,
            created_at: timestamp,
          }

          await this.$store.dispatch('farm/addWqs', payload)
        }
      }
    },
  }
}
</script>
