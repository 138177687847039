<template>
  <div>
    <div class="card mb-5">
      <div class="card-content">
        <ValidationObserver ref="add-pond">
          <form @submit.prevent="addPond">
            <div class="columns is-tablet">
              <div class="column is-one-quarter-tablet">
                <ValidationProvider mode="passive" :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.pond')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="text" v-model="form.name"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-one-quarter-tablet">
                <ValidationProvider mode="passive" :name="$t('farm.block')" rules="required" v-slot="{ errors }">
                  <b-field :label="$t('farm.block')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="text" v-model="form.block_name"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-one-quarter-tablet">
                <ValidationProvider mode="passive" :name="$t('farm.area')" rules="required" v-slot="{ errors }">
                  <b-field :label="`${$t('farm.area')} (m2)`" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" step="any" v-model.number="form.area"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-one-quarter-tablet">
                <ValidationProvider mode="passive" :name="`Total ${$t('feed_tray.feed_tray')}`" rules="required" v-slot="{ errors }">
                  <b-field :label="`Total ${$t('feed_tray.feed_tray')}`" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="number" v-model.number="form.anco"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="has-text-right">
              <b-button type="is-primary" native-type="submit">{{ $t('add') }}</b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <div class="card">
      <div class="card-content">
        <b-table :mobile-cards="false" :data="ponds">

          <template #empty>
            <p class="has-text-centered">{{ $t('no') }} Data</p>
          </template>

          <b-table-column field="name" :label="$t('farm.pond')" v-slot="props">
            <ValidationProvider :name="$t('farm.pond')" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]" class="mb-0">
                <b-input type="text" v-model="props.row.name"/>
              </b-field>
            </ValidationProvider>
          </b-table-column>

          <b-table-column field="block_name" :label="$t('farm.block')" v-slot="props">
            <ValidationProvider :name="$t('farm.block')" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]" class="mb-0">
                <b-input type="text" v-model="props.row.block_name"/>
              </b-field>
            </ValidationProvider>
          </b-table-column>

          <b-table-column field="area" :label="`${$t('farm.area')} (m2)`" v-slot="props">
            <ValidationProvider :name="$t('farm.area')" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]" class="mb-0">
                <b-input type="number" step="any" v-model.number="props.row.area"/>
              </b-field>
            </ValidationProvider>
          </b-table-column>

          <b-table-column field="anco" :label="`Total ${$t('feed_tray.feed_tray')}`" v-slot="props">
            <ValidationProvider :name="`Total ${$t('feed_tray.feed_tray')}`" rules="required" v-slot="{ errors }">
              <b-field :type="errors[0] ? 'is-danger' : ''" :message="errors[0]" class="mb-0">
                <b-input type="number" step="any" v-model.number="props.row.anco"/>
              </b-field>
            </ValidationProvider>
          </b-table-column>

          <b-table-column field="action" label="" v-slot="props">
            <b-button type="is-danger is-light" icon-right="trash-can-outline" @click="deletePond(props.index)"/>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "AddPond",
  data: () => {
    return {
      form: {},
      ponds: []
    }
  },
  watch: {
    ponds: {
      deep:true,
      handler() {
        this.$emit('update-data', {
          canContinue: this.ponds.length > 0,
          data: this.ponds
        })
      }
    }
  },
  methods: {
    addPond() {
      this.$refs['add-pond'].validate().then(async success => {
        if (!success) return 0

        let form = this.form

        this.ponds.push({
          name: form.name,
          block_name: form.block_name,
          area: form.area,
          anco: form.anco,
          status: 0,
        })

        this.$emit('update-data', {
          canContinue: this.ponds.length > 0,
          data: this.ponds
        })

        this.form = {}
      })
    },
    deletePond(index) {
      this.ponds.splice(index, 1)

      this.$emit('update-data', {
        canContinue: this.ponds.length > 0,
        data: this.ponds
      })
    },
    canContinue() {
      return this.ponds.length > 0
    }
  }
}
</script>
