<template>
  <div>
    <div class="card mb-5">
      <div class="card-content">
        <ValidationObserver ref="add-member">
          <form @submit.prevent="addUser">
            <div class="columns is-tablet">
              <div class="column is-4-tablet">
                <ValidationProvider mode="passive" name="Email" rules="required" v-slot="{ errors }">
                  <b-field label="Email" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <b-input type="email" id="email" v-model="form.email"></b-input>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-4-tablet">
                <ValidationProvider mode="passive" name="Role" rules="required" v-slot="{ errors }">
                  <b-field label="User Role" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select type="is-secondary is-light" no-outline expanded
                               :items="roles"
                               v-model="form.role" placeholder="Select Role"></sg-select>
                  </b-field>
                </ValidationProvider>
              </div>
              <div class="column is-4-tablet">
                <ValidationProvider mode="passive" :name="$t('farm.pond')" :rules="form.role && ![1,2].includes(form.role.value) ? 'required' : ''" v-slot="{ errors }">
                  <b-field :label="$t('farm.pond')" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                    <sg-select type="is-secondary is-light"
                               no-outline expanded multiple
                               v-model="form.ponds" :items="ponds ? ponds.map(e=>e.name) : []"
                               :disabled="!form.role || [1,2,undefined].includes(form.role.value)"
                               :placeholder="`${$t('select')} ${$t('farm.pond')}`"/>
                  </b-field>
                </ValidationProvider>
              </div>
            </div>
            <div class="has-text-right">
              <b-button type="is-primary" native-type="submit">{{ $t('add') }}</b-button>
            </div>
          </form>
        </ValidationObserver>
      </div>
    </div>

    <div class="card">
      <div class="card-content">
        <b-table :mobile-cards="false" :data="users">

          <template #empty>
            <p class="has-text-centered">{{ $t('no') }} Data</p>
          </template>

          <b-table-column field="email" label="Email" v-slot="props">
            <ValidationProvider name="Email" rules="required" v-slot="{ errors }">
              <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <b-input v-model="props.row.email"/>
              </b-field>
            </ValidationProvider>
          </b-table-column>

          <b-table-column field="role" label="Role" v-slot="props">
            <ValidationProvider mode="passive" name="Role" rules="required" v-slot="{ errors }">
              <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <sg-select type="is-secondary is-light" no-outline expanded
                           :items="roles"
                           v-model="props.row.role" placeholder="Select Role"></sg-select>
              </b-field>
            </ValidationProvider>
          </b-table-column>

          <b-table-column field="ponds" :label="$t('farm.pond')" v-slot="props">
            <ValidationProvider mode="passive" :name="$t('farm.pond')" :rules="props.row.role && ![1,2].includes(props.row.role.value) ? 'required' : ''" v-slot="{ errors }">
              <b-field class="mb-0" :type="errors[0] ? 'is-danger' : ''" :message="errors[0]">
                <sg-select type="is-secondary is-light"
                           no-outline expanded multiple
                           v-model="props.row.ponds" :items="ponds.map(e=>e.name)"
                           :disabled="!props.row.role || [1,2,undefined].includes(props.row.role.value)"
                           :placeholder="`${$t('select')} ${$t('farm.pond')}`"/>
              </b-field>
            </ValidationProvider>
          </b-table-column>

          <b-table-column field="action" label="" v-slot="props">
            <b-button type="is-danger is-light" icon-right="trash-can-outline" @click="deleteUser(props.index)"/>
          </b-table-column>
        </b-table>
      </div>
    </div>
  </div>
</template>

<script>
import SgSelect from "@/components/Sg/SgSelect";

export default {
  name: "AddMember",
  components: {SgSelect},
  props: {
    ponds: Array
  },
  data: () => {
    return {
      form: {},
      users: [],
      filteredPonds: [],
    }
  },
  computed: {
    roles() {
      return this.$store.state.farm.roles.map(e => {
        return {
          value: e.role_id,
          label: e.role_name,
        }
      })
    }
  },
  watch: {
    users: {
      deep:true,
      handler() {
        this.$emit('update-data', {
          canContinue: true,
          data: this.users
        })
      }
    }
  },
  created() {
    this.getRole()
  },
  methods: {
    getRole() {
      this.$loading()
      this.$store.dispatch('farm/getRole')
      this.$loading(false)
    },

    addUser() {
      this.$refs['add-member'].validate().then(async success => {
        if (!success) return 0

        let form = this.form

        this.users.push({
          email: form.email,
          role: form.role,
          ponds: form.ponds,
        })

        this.$emit('update-data', {
          canContinue: true,
          data: this.users
        })

        this.form = {}
      })
    },
    deleteUser(index) {
      this.users.splice(index, 1)

      this.$emit('update-data', {
        canContinue: true,
        data: this.users
      })
    },
    canContinue() {
      return true
    }
  }
}
</script>
