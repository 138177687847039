var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',[_c('div',{staticClass:"card mb-5"},[_c('div',{staticClass:"card-content"},[_c('ValidationObserver',{ref:"add-pond"},[_c('form',{on:{"submit":function($event){$event.preventDefault();return _vm.addPond.apply(null, arguments)}}},[_c('div',{staticClass:"columns is-tablet"},[_c('div',{staticClass:"column is-one-quarter-tablet"},[_c('ValidationProvider',{attrs:{"mode":"passive","name":_vm.$t('farm.pond'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.pond'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.form.name),callback:function ($$v) {_vm.$set(_vm.form, "name", $$v)},expression:"form.name"}})],1)]}}])})],1),_c('div',{staticClass:"column is-one-quarter-tablet"},[_c('ValidationProvider',{attrs:{"mode":"passive","name":_vm.$t('farm.block'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":_vm.$t('farm.block'),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text"},model:{value:(_vm.form.block_name),callback:function ($$v) {_vm.$set(_vm.form, "block_name", $$v)},expression:"form.block_name"}})],1)]}}])})],1),_c('div',{staticClass:"column is-one-quarter-tablet"},[_c('ValidationProvider',{attrs:{"mode":"passive","name":_vm.$t('farm.area'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":((_vm.$t('farm.area')) + " (m2)"),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","step":"any"},model:{value:(_vm.form.area),callback:function ($$v) {_vm.$set(_vm.form, "area", _vm._n($$v))},expression:"form.area"}})],1)]}}])})],1),_c('div',{staticClass:"column is-one-quarter-tablet"},[_c('ValidationProvider',{attrs:{"mode":"passive","name":("Total " + (_vm.$t('feed_tray.feed_tray'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{attrs:{"label":("Total " + (_vm.$t('feed_tray.feed_tray'))),"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number"},model:{value:(_vm.form.anco),callback:function ($$v) {_vm.$set(_vm.form, "anco", _vm._n($$v))},expression:"form.anco"}})],1)]}}])})],1)]),_c('div',{staticClass:"has-text-right"},[_c('b-button',{attrs:{"type":"is-primary","native-type":"submit"}},[_vm._v(_vm._s(_vm.$t('add')))])],1)])])],1)]),_c('div',{staticClass:"card"},[_c('div',{staticClass:"card-content"},[_c('b-table',{attrs:{"mobile-cards":false,"data":_vm.ponds},scopedSlots:_vm._u([{key:"empty",fn:function(){return [_c('p',{staticClass:"has-text-centered"},[_vm._v(_vm._s(_vm.$t('no'))+" Data")])]},proxy:true}])},[_c('b-table-column',{attrs:{"field":"name","label":_vm.$t('farm.pond')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.pond'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text"},model:{value:(props.row.name),callback:function ($$v) {_vm.$set(props.row, "name", $$v)},expression:"props.row.name"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"field":"block_name","label":_vm.$t('farm.block')},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.block'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"text"},model:{value:(props.row.block_name),callback:function ($$v) {_vm.$set(props.row, "block_name", $$v)},expression:"props.row.block_name"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"field":"area","label":((_vm.$t('farm.area')) + " (m2)")},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"name":_vm.$t('farm.area'),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","step":"any"},model:{value:(props.row.area),callback:function ($$v) {_vm.$set(props.row, "area", _vm._n($$v))},expression:"props.row.area"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"field":"anco","label":("Total " + (_vm.$t('feed_tray.feed_tray')))},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('ValidationProvider',{attrs:{"name":("Total " + (_vm.$t('feed_tray.feed_tray'))),"rules":"required"},scopedSlots:_vm._u([{key:"default",fn:function(ref){
var errors = ref.errors;
return [_c('b-field',{staticClass:"mb-0",attrs:{"type":errors[0] ? 'is-danger' : '',"message":errors[0]}},[_c('b-input',{attrs:{"type":"number","step":"any"},model:{value:(props.row.anco),callback:function ($$v) {_vm.$set(props.row, "anco", _vm._n($$v))},expression:"props.row.anco"}})],1)]}}],null,true)})]}}])}),_c('b-table-column',{attrs:{"field":"action","label":""},scopedSlots:_vm._u([{key:"default",fn:function(props){return [_c('b-button',{attrs:{"type":"is-danger is-light","icon-right":"trash-can-outline"},on:{"click":function($event){return _vm.deletePond(props.index)}}})]}}])})],1)],1)])])}
var staticRenderFns = []

export { render, staticRenderFns }